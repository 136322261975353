import React from 'react'
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
// import reportWebVitals from './reportWebVitals';

// import "slick-carousel/slick/slick.css"
// import "slick-carousel/slick/slick-theme.css"

import './style.module.css'
import Home from './views/home'

// import ThreeTest from './views/ThreeTest'
import App from './App'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyADnMvs5HKPMZkTqB6rvMZ32y1hc-x_FIM",
  authDomain: "one-magic-school.firebaseapp.com",
  projectId: "one-magic-school",
  storageBucket: "one-magic-school.appspot.com",
  messagingSenderId: "1046270713726",
  appId: "1:1046270713726:web:f9db08dad124e2a001dd2f",
  measurementId: "G-W2ZC4WSJ73"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


const Web = () => {
  return (
    <BrowserRouter>
    <Routes>
        
     <Route path="/" element={<Home />} />
     <Route path="/home" element={<Home />} />
     <Route path="/Test" element={<App />} />

    </Routes>
    </BrowserRouter>
  )
}

function reveal() {
  var reveals = document.querySelectorAll(".reveal");
  console.log(reveals.length);
  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);


// ReactDOM.render(<Web />, document.getElementById('root'))


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Web />
  </React.StrictMode>
);