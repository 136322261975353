import React from 'react'

import PropTypes from 'prop-types'

import Divider from './divider'
import OutlineBlueButton from './outline-blue-button'
import SocialMedia from './social-media'
import projectStyles from '../style.module.css'
import styles from './burger-menu.module.css'

const BurgerMenu = (props) => {
  return (
    <div className={` ${styles['container']} ${styles[props.rootClassName]} `}>
      <div className={styles['container1']}>
        <div className={styles['container2']}>
          <img
            alt={props.image_alt}
            src={props.image_src}
            className={styles['logo']}
          />
        </div>
        <div className={styles['menu']}>
          <ul className={` ${styles['ul']} ${projectStyles['list']} `}>
            <li className={` ${styles['li']} ${projectStyles['lead']} `}>
              <span className={styles['text']}>{props.MenuItem1}</span>
              <Divider rootClassName="rootClassName2"></Divider>
            </li>
            <li className={` ${styles['li1']} ${projectStyles['lead']} `}>
              <a
                href={props.MenuItem22}
                target="_blank"
                rel="noreferrer noopener"
                className={styles['link']}
              >
                {props.MenuItem2}
              </a>
              <Divider rootClassName="rootClassName3"></Divider>
            </li>
            <li className={` ${styles['li2']} ${projectStyles['lead']} `}>
              <span className={styles['text1']}>{props.MenuItem3}</span>
              <Divider rootClassName="rootClassName7"></Divider>
            </li>
            <li className={` ${styles['li3']} ${projectStyles['lead']} `}>
              <a
                href={props.link_text21}
                target="_blank"
                rel="noreferrer noopener"
                className={styles['link1']}
              >
                {props.MenuItem4}
              </a>
              <Divider rootClassName="rootClassName5"></Divider>
            </li>
          </ul>
        </div>
        <OutlineBlueButton
          button="Buy Now"
          rootClassName="rootClassName1"
        ></OutlineBlueButton>
        <div className={styles['container3']}></div>
        <SocialMedia rootClassName="rootClassName"></SocialMedia>
      </div>
    </div>
  )
}

BurgerMenu.defaultProps = {
  image_src: '/playground_assets/logo%20design%202-200h.png',
  MenuItem4: 'Whitepaper',
  link_text: 'https://example.com',
  rootClassName: '',
  image_alt: 'image',
  MenuItem2: 'About',
  link_text21: 'https://example.com',
  MenuItem1: 'Home',
  MenuItem3: 'News',
  MenuItem21: 'About',
  text: 'About',
  MenuItem12: 'Home',
  MenuItem22: 'News',
}

BurgerMenu.propTypes = {
  image_src: PropTypes.string,
  MenuItem4: PropTypes.string,
  link_text: PropTypes.string,
  rootClassName: PropTypes.string,
  image_alt: PropTypes.string,
  MenuItem2: PropTypes.string,
  link_text21: PropTypes.string,
  MenuItem1: PropTypes.string,
  MenuItem3: PropTypes.string,
  MenuItem21: PropTypes.string,
  text: PropTypes.string,
  MenuItem12: PropTypes.string,
  MenuItem22: PropTypes.string,
}

export default BurgerMenu
