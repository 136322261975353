import React, {useState} from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import SocialMedia from './social-media'
import OutlineBlueButton from './outline-blue-button'
import Divider from './divider'
import projectStyles from '../style.module.css'
import styles from './header.module.css'


const Header = (props) => {

  const [burgerState, setBurgerState] = useState(false);

  const toggleBurgerMenu = () => setBurgerState(!burgerState);

  return (
    <div data-role="Header" className={styles['Header']}>
      <img
        alt={props.image_alt1}
        src={props.image_src1}
        className={styles['image']}
      />
      <nav className={styles['Nav']}>
        <img
          alt={props.image_alt}
          src={props.image_src}
          className={styles['image1']}
        />
        <Link to="" className={styles['BurgerButton']} onClick={toggleBurgerMenu}>
          <svg viewBox="0 0 1024 1024" className={styles['BurgerIcon']}>
            <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
          </svg>
        </Link>
        <div className={styles['container']}>
        <div className={` ${styles['MenuLeft']} ${projectStyles['large']} `}>
            <Link
              to="/"
              className={` ${styles['navlink1']} ${projectStyles['medium']} ${projectStyles['menu-item']} `}
            >
              Home
            </Link>
            {/* <a
              href="https://docs.google.com/presentation/d/1rQguxb0wjwL58mqMM7Epe4llmyUWd5CZ/edit?usp=sharing&ouid=113366035409979683331&rtpof=true&sd=true"
              target="_blank"
              rel="noreferrer noopener"
              className={` ${styles['link1']} ${projectStyles['medium']} ${projectStyles['menu-item']} `}
            >
              <span>Home</span>
            </a> */}
            <Link
              to="/"
              onClick= {props.handleScroll}
              className={` ${styles['navlink1']} ${projectStyles['medium']} ${projectStyles['menu-item']} `}
            >
              About Us
            </Link>
            {/* <a
              onClick= {props.handleScroll}
    
              className={` ${styles['Link']} ${projectStyles['medium']} ${projectStyles['menu-item']} `}
            >
              About
            </a> */}
            <a
              href="https://onemagicschool.substack.com/"
              target="_blank"
              rel="noreferrer noopener"
              className={` ${styles['Link']} ${projectStyles['medium']} ${projectStyles['menu-item']} `}
            >
              News
            </a>
            <a
              href="https://drive.google.com/file/d/1cTTr-8V5mlAczH2YgqBVr4-hOGsaMh20/view?usp=sharing"
              target="_blank"
              rel="noreferrer noopener"
              className={` ${styles['Link']} ${projectStyles['medium']} ${projectStyles['menu-item']} `}
            >
              Whitepaper
            </a>
            
          </div>
          <div className={styles['MenuRight']}>
            <div className={styles['SocialMediaContainer']}>
              <SocialMedia rootClassName="rootClassName1"></SocialMedia>
            </div>
            <div className={styles['MintButtonContainer']}>
              <OutlineBlueButton
                button="Open App"
                rootClassName="rootClassName"
              ></OutlineBlueButton>

            </div>
            <div className={styles['SocialMediaContainer2']}>
              <a
                href="https://www.facebook.com/onemagicschool"
                target="_blank"
                rel="noreferrer noopener"
              >
                <i 
                className={`${styles['icon01']} fab fa-facebook`}
                ></i>
              </a>
              <a
                href="https://twitter.com/OneMagicSchool"
                target="_blank"
                rel="noreferrer noopener"
              >
                <i 
                className={`${styles['icon01']} fab fa-twitter`}
                ></i>
              </a>
              <a
                href="https://www.instagram.com/onemagicschool/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <i 
                className={`${styles['icon01']} fab fa-instagram`}
                ></i>
              </a>
              <a
                href="https://www.linkedin.com/company/one-magic-school/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <i 
                className={`${styles['icon01']} fab fa-linkedin`}
                ></i>
              </a>

              <a
                href="https://discord.gg/SGetsthbJB"
                target="_blank"
                rel="noreferrer noopener"
              >
                <i 
                className={`${styles['icon01']} fab fa-discord`}
                ></i>
              </a>
              
            </div>
            
          </div>
        </div>
      </nav>
      <div className={styles[burgerState?'BurgerMenuContainerActive':'BurgerMenuContainer']}>
        <div className={styles['BurgerMenu']}>
          <div className={styles['LogoContainer']}>
            <img
              alt={props.image_alt2}
              src={props.image_src2}
              className={styles['logo']}
            />
          </div>

          <div className={styles['menu']}>
            <ul className={` ${styles['ul']} ${projectStyles['list']} `}>
              <li className={` ${styles['li']} ${projectStyles['lead']} `}>
                <Link onClick={toggleBurgerMenu}
                  to="/"
                  className={` ${styles['navlink3']} ${projectStyles['menu-item']} `}
                >
                  {props.MenuItem1}
                </Link>
                <Divider rootClassName="rootClassName8"></Divider>
              </li>
              <li className={` ${styles['li1']} ${projectStyles['lead']} `}>
                <Link onClick={toggleBurgerMenu}
                  to="/"
                  className={` ${styles['navlink4']} ${projectStyles['menu-item']} `}
                >
                  {props.MenuItem2}
                </Link>
                <Divider rootClassName="rootClassName9"></Divider>
              </li>
              <li className={` ${styles['li2']} ${projectStyles['lead']} `}>
                <a onClick={toggleBurgerMenu}
                  href="https://onemagicschool.substack.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className={` ${styles['link5']} ${projectStyles['menu-item']} `}
                >
                  {props.MenuItem3}
                </a>
                <Divider rootClassName="rootClassName10"></Divider>
              </li>
              <li className={` ${styles['li3']} ${projectStyles['lead']} `}>
                <a onClick={toggleBurgerMenu}
                  href="https://docs.google.com/document/d/1C5mkRm2s4lEgrXm0UtHlMzFExJQbU7s9fryl5RnqXeM/edit?usp=sharing"
                  target="_blank"
                  rel="noreferrer noopener"
                  className={` ${styles['link6']} ${projectStyles['menu-item']} `}
                >
                  {props.MenuItem4}
                </a>
                <Divider rootClassName="rootClassName11"></Divider>
              </li>
            </ul>
          </div>
          <OutlineBlueButton
            button="Buy Now"
            rootClassName="rootClassName2"
          ></OutlineBlueButton>
          <div className={styles['Space']}></div>
          <SocialMedia rootClassName="rootClassName3"></SocialMedia>
        </div>
        <div className={styles['XiconContainer']}>
          <Link to="" onClick={toggleBurgerMenu}>
            <svg viewBox="0 0 1024 1024" className={styles['Xicon']}>
              <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM353.835 414.165l97.835 97.835-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l97.835-97.835 97.835 97.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-97.835-97.835 97.835-97.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-97.835 97.835-97.835-97.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  )
}

Header.defaultProps = {
  image_alt: 'image',
  image_src: '/playground_assets/Logo No Tower h300 8bit.png',
  image_alt1: 'image',
  image_src1: '/playground_assets/Logo Tower 500px 8bit.png',
  rootClassName: '',
  image_src2: '/playground_assets/Logo Tower 500px 8bit.png',
  image_alt2: 'image',
  MenuItem1: 'Home',
  MenuItem22: 'News',
  MenuItem2: 'About',
  MenuItem3: 'News',
  MenuItem4: 'Whitepaper',
  link_text21: 'https://example.com',
}

Header.propTypes = {
  image_alt: PropTypes.string,
  image_src: PropTypes.string,
  image_alt1: PropTypes.string,
  image_src1: PropTypes.string,
  rootClassName: PropTypes.string,
  image_src2: PropTypes.string,
  image_alt2: PropTypes.string,
  MenuItem1: PropTypes.string,
  MenuItem22: PropTypes.string,
  MenuItem2: PropTypes.string,
  MenuItem3: PropTypes.string,
  MenuItem4: PropTypes.string,
  link_text21: PropTypes.string,
}

export default Header