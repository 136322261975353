import {useState,useEffect} from 'react';



const styles = {
  root: {

    position: 'fixed',
    bottom: '5vh',
    right: '5vw',
    zIndex: 20,
    width: 'auto',
    height: 'auto',
    cursor: 'pointer'

  },
  icon: {
    width: 'auto',
    height: '5vh'
  },
} ;

function SoundControl(props) {
  const [audio] = useState(new Audio(props.sound));
  const [playing, setPlaying] = useState(false);
  const [icon] = useState({on:'/sound/music_on.png',off:'/sound/music_off.png'});
  useEffect(() => {
    audio.volume = 0.5;
    audio.loop = true;
    setPlaying(true);
  }, []);
  useEffect(() => {
    if(playing)
    {
      audio.play();
    }
    else {
      audio.pause();
    }
    
  }, [playing]);
  return (
    <>
      <div
        style={styles.root}
      >
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} onClick={()=>setPlaying(!playing)} >
         <img src={playing?icon.on:icon.off} style={styles.icon}  />
          
        </div>
      </div>
    </>
  );
}


export default SoundControl;
