import React from 'react'

import PropTypes from 'prop-types'

import projectStyles from '../style.module.css'
import styles from './phase1.module.css'

const Phase1 = (props) => {
  return (
    <div className={styles['Phase1']}>
      <div className={styles['container']}>
        <div className={styles['container1']}>
          <div
            className={` ${styles['container2']} ${projectStyles['RoadmapStep']} `}
          >
            <span
              className={` ${styles['text']} ${projectStyles['headingTwo']} `}
            >
              {props.Phase}
            </span>
            <div className={styles['container3']}></div>
          </div>
        </div>
        <ul className={` ${projectStyles['list']} ${projectStyles['body']} `}>
          <li className={projectStyles['list-item']}>
            <span>{props.text}</span>
          </li>
          <li className={projectStyles['list-item']}>
            <span>{props.text1}</span>
          </li>
          <li className={projectStyles['list-item']}>
            <span>{props.text2}</span>
          </li>
        </ul>
      </div>
      <div className={styles['VerticalLine']}></div>
    </div>
  )
}

Phase1.defaultProps = {
  text1: 'Funding.',
  text2: 'Blockchain research, choosing the optimal platform.',
  text: 'Game core, proof of concept development (MVP).',
  Phase: 'Phase 1: Preparation',
}

Phase1.propTypes = {
  text1: PropTypes.string,
  text2: PropTypes.string,
  text: PropTypes.string,
  Phase: PropTypes.string,
}

export default Phase1
