import React from 'react'

import PropTypes from 'prop-types'

import projectStyles from '../style.module.css'
import styles from './phase2.module.css'

const Phase2 = (props) => {
  return (
    <div className={styles['Phase2']}>
      <div className={styles['container']}>
        <div
          className={` ${styles['container1']} ${projectStyles['RoadmapStep']} `}
        >
          <div className={styles['container2']}></div>
          <span
            className={` ${styles['text']} ${projectStyles['headingTwo']} `}
          >
            {props.Phase}
          </span>
        </div>
        <ul className={` ${projectStyles['list']} ${projectStyles['body']} `}>
          <li className={projectStyles['list-item']}>
            <span>{props.text}</span>
          </li>
          <li className={projectStyles['list-item']}>
            <span>{props.text1}</span>
          </li>
          <li className={projectStyles['list-item']}>
            <span>{props.text2}</span>
          </li>
        </ul>
      </div>
      <div className={styles['VerticalLine']}></div>
    </div>
  )
}

Phase2.defaultProps = {
  text2: 'Genesis NFT sale (Magic Disciples).',
  rootClassName: '',
  Phase: 'Phase 2: Private Sale',
  text1: 'SHARD token private sale 1, 2, 3.',
  text: 'Game core development and blockchain integration.',
}

Phase2.propTypes = {
  text2: PropTypes.string,
  rootClassName: PropTypes.string,
  Phase: PropTypes.string,
  text1: PropTypes.string,
  text: PropTypes.string,
}

export default Phase2
