import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import SocialMedia from './social-media'
import styles from './footer.module.css'

const Footer = (props) => {
  return (
    <footer className={` ${styles['Footer']} ${styles[props.rootClassName]} `}>
      <div className={styles['container']}>
        <img
          alt={props.image_alt}
          src={props.image_src}
          className={styles['image']}
        />
        <div className={styles['Line']}></div>
        <div className={styles['container1']}>
          <div className={styles['container2']}>
            <Link to="/home" className={styles['navlink']}>
              Home
            </Link>
            <Link to="/home" className={styles['navlink']}>
              About Us
            </Link>
            <a
              href='https://drive.google.com/file/d/1cTTr-8V5mlAczH2YgqBVr4-hOGsaMh20/view?usp=sharing'
              target="_blank"
              rel="noreferrer noopener"
              className={styles['link']}
            >
              Whitepaper
            </a>
          </div>
          <div className={styles['container3']}>
          <a
              href='https://discord.gg/SGetsthbJB'
              target="_blank"
              rel="noreferrer noopener"
              className={styles['link']}
            >
              Community
            </a>
            <a
              href="https://onemagicschool.substack.com/"
              target="_blank"
              rel="noreferrer noopener"
              className={styles['link']}
            >
              News
            </a>
            <Link to="/home" className={styles['navlink']}>
              Contact
            </Link>
          </div>
        </div>
        <div className={styles['Line1']}></div>
      </div>
      <div className={styles['container4']}>
        <div className={styles['container5']}>
          <SocialMedia rootClassName="rootClassName2"></SocialMedia>
          <span className={styles['text']}>{props.text}</span>
        </div>
      </div>
    </footer>
  )
}

Footer.defaultProps = {

    
  News: 'News',

  image_alt: 'image',

  text: '© 2022 One Magic School',


  image_src: '/playground_assets/Logo Tower 500px 8bit.png',

  rootClassName: '',
  Home: 'Home',
  Copyright: '© 2022 One Magic School',
}

Footer.propTypes = {

  News: PropTypes.string,
  Home1: PropTypes.string,
  image_alt: PropTypes.string,
  text23: PropTypes.string,
  text: PropTypes.string,
  NewsLink: PropTypes.string,
  link_text2: PropTypes.string,
  link_text11: PropTypes.string,
  text22: PropTypes.string,
  Contact: PropTypes.string,
  image_src: PropTypes.string,
  text21: PropTypes.string,
  rootClassName: PropTypes.string,
  Home: PropTypes.string,
  Copyright: PropTypes.string,
}

export default Footer
