import React, {useRef, useState} from 'react'
import './App.css';

import {Canvas} from '@react-three/fiber/'
import {Suspense} from 'react'
// import { useLoader } from '@react-three/fiber'
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import {Environment, OrbitControls} from '@react-three/drei'

import ForegroundTrees from './views/ForegroundTrees.js'
import Leaf from './views/Leaf.js'


function App() {
  return (
    <>
      <div className="canvasContainer">
        <Canvas>
          <Suspense fallback={null}>
            <ForegroundTrees/>
            <Leaf/>
            <OrbitControls/>
            <Environment preset="sunset" background/>
          </Suspense>
        </Canvas>
      </div>
    </>
  );
}

export default App;

