import {useEffect, useRef, useState} from 'react';
import handleViewport from 'react-in-viewport';

const LazyImage = (_props) => {
  function elementInViewport(el) {
    const rect = el.current.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  const [status, setStatus] = useState('init');
  const {src, inViewport, autoLoad, placeHolder, ...props} = _props;

  let placeHolderSrc = placeHolder || src;
  if (!placeHolder && /^\/playground_assets\/.+(?:\.png|\.jpg)$/g.test(src)) {
    placeHolderSrc = src.replaceAll(/^\/playground_assets\/(.+)$/g, '/playground_assets/placeHolder/$1');
  }
  const imgElm = useRef();
  useEffect(() => {
    if (!autoLoad && !elementInViewport(imgElm)) {
      return;
    }
    if (status === 'init') {
      setStatus('loading');
    }
    const imgLoader = new Image();
    imgLoader.src = src;
    imgLoader.onload = () => {
      setStatus('loaded')
      imgElm.src = src;
    }
  }, [window.scrollY])
  return <img ref={imgElm} src={status === 'loaded' && src || placeHolderSrc} {...props}/>
}
export default handleViewport(LazyImage);