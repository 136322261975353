import React from 'react'

import PropTypes from 'prop-types'

import projectStyles from '../style.module.css'
import styles from './name-card.module.css'
import LazyImage from './LazyImage';

const NameCard = (props) => {
  return (
    <div className={` ${styles['container']} ${styles[props.rootClassName]} `}>
      <LazyImage
        alt={props.image_alt}
        src={props.image_src}
        className={styles['image']}
      />
      <h5 className={` ${styles['text']} ${projectStyles['headingThree']} `}>
        {props.Name}
      </h5>
      <span className={styles['text1']}>{props.Title}</span>
      <div className={styles['container1']}>
        <a
          href={props.linkedIn}
          target="_blank"
          rel="noreferrer noopener"
          className={styles[props.displayLinkedin]}
        >
          <svg viewBox="0 0 877.7142857142857 1024" className={styles['icon']}>
            <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
          </svg>
        </a>
        <a
          href={props.twitterIcon}
          target="_blank"
          rel="noreferrer noopener"
          className={styles[props.displayTwitter]}
          // className={styles['link1']}
        >
          <svg viewBox="0 0 877.7142857142857 1024" className={styles['icon']}>
            <path d="M731.429 348.571c-21.714 9.714-44.571 16-69.143 19.429 25.143-14.857 44-38.857 53.143-66.857-23.429 13.714-49.143 24-76.571 29.143-21.714-23.429-53.143-37.714-87.429-37.714-66.286 0-120 53.714-120 120 0 9.143 0.571 18.857 2.857 27.429-100-5.143-188.571-52.571-248-125.714-10.286 17.714-16.571 38.857-16.571 60.571 0 41.714 19.429 78.286 52 100-20-0.571-38.857-6.286-57.143-14.857v1.143c0 58.286 44 106.857 98.857 117.714-10.286 2.857-18.286 4.571-29.143 4.571-7.429 0-14.857-1.143-22.286-2.286 15.429 47.429 59.429 82.286 112 83.429-41.143 32-92.571 51.429-149.143 51.429-9.714 0-19.429-0.571-28.571-1.714 53.143 33.714 116 53.714 184 53.714 220.571 0 341.714-182.857 341.714-341.714 0-5.143 0-10.286-0.571-15.429 23.429-16.571 44-37.714 60-62.286zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
          </svg>
        </a>
        <a
          href={props.facebookIcon}
          target="_blank"
          rel="noreferrer noopener"
          className={styles[props.displayFB]}
        >
          <svg viewBox="0 0 877.7142857142857 1024" className={styles['icon']}>
            <path d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"></path>
          </svg>
        </a>
        <a
          href={props.instagramIcon}
          target="_blank"
          rel="noreferrer noopener"
          className={styles[props.displayInsta]}
        >
          <svg viewBox="0 0 877.7142857142857 1024" className={styles['icon']}>
            <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
          </svg>
        </a>
        <a
          href={props.artStation}
          target="_blank"
          rel="noreferrer noopener"
          className={styles[props.displayArtStation]}
        >
          <i 
            // class='fab fa-artstation'
            className={`${styles['icon']} fab fa-artstation`}
            ></i>
        </a>
        
        {/* <i class="fab fa-artstation"></i> */}

      </div>
    </div>
  )
}

NameCard.defaultProps = {
  Title: 'Founder, Creative Director',
  Name: 'An Phan',
  image_src: '/playground_assets/char-rogue.jpg',
  image_alt: 'image',
  rootClassName: '',
  linkedIn: 'https://linkedin.com/',
  twitterIcon: 'https://twitter.com/',
  facebookIcon: 'https://facebook.com',
  instagramIcon: 'https://instagram.com',
  artStation: 'https://artstation.com',
  displayLinkedin: 'none',
  displayTwitter: 'none',
  displayFB: 'none',
  displayInsta: 'none',
  displayArtStation: 'none'
}

// NameCard.propTypes = {
//   Title: PropTypes.string,
//   Name: PropTypes.string,
//   image_src: PropTypes.string,
//   image_alt: PropTypes.string,
//   rootClassName: PropTypes.string,
//   linkedIn: PropTypes.string,
//   twitterIcon: PropTypes.string,
//   facebookIcon: PropTypes.string,
//   instagramIcon: PropTypes.string,
//   artstationIcon: PropTypes.string,
// }

export default NameCard
