import React from 'react'

import PropTypes from 'prop-types'

import styles from './divider.module.css'

const Divider = (props) => {
  return (
    <div
      className={` ${styles['Divider']} ${styles[props.rootClassName]} `}
    ></div>
  )
}

Divider.defaultProps = {
  rootClassName: '',
}

Divider.propTypes = {
  rootClassName: PropTypes.string,
}

export default Divider
