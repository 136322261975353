import React from 'react'

import PropTypes from 'prop-types'

import styles from './outline-blue-button.module.css'

const OutlineBlueButton = (props) => {
  return (
    <div className={` ${styles['container']} ${styles[props.rootClassName]} `}>
      <a
                href={props.link}
                target={props.target}
                rel="noreferrer noopener"
              >
      <button className={styles['button']}>
        {props.button}</button>
        </a>
    </div>
  )
}

OutlineBlueButton.defaultProps = {
  button: 'Button',
  link: 'https://app.onemagicschool.com/login',
  rootClassName: '',
  target: "_blank"
}

// OutlineBlueButton.propTypes = {
//   button: PropTypes.string,
//   rootClassName: PropTypes.string,
// }

export default OutlineBlueButton
