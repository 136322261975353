import React from 'react'

import PropTypes from 'prop-types'

import projectStyles from '../style.module.css'
import styles from './n-f-t-info-card.module.css'
import LazyImage from './LazyImage';

const NFTInfoCard = (props) => {
  return (
    <div className={styles['container']}>
      <div className={styles['container01']}>
        <LazyImage
          alt={props.image_alt}
          src={props.NFTimage}
          className={styles['image']}
        />
      </div>
      <div className={styles['container02']}>
        <span className={` ${styles['text']} ${projectStyles['headingOne']} `}>
          {props.NFTname}
        </span>
        <div className={styles['container03']}>
          <div className={styles['container04']}>
            <span
              className={` ${styles['text01']} ${projectStyles['headingTwo']} `}
            >
              Stats
            </span>
            <div className={styles['container05']}>
              <div className={styles['container06']}>
                <span
                  className={` ${styles['text02']} ${projectStyles['body']} `}
                >
                  Strength
                </span>
                <span
                  className={` ${styles['text03']} ${projectStyles['body']} `}
                >
                  Intelligent
                </span>
                <span
                  className={` ${styles['Text04']} ${projectStyles['body']} `}
                >
                  Agility
                </span>
                <span
                  className={` ${styles['Text05']} ${projectStyles['body']} `}
                >
                  Earth
                </span>
                <span
                  className={` ${styles['Text06']} ${projectStyles['body']} `}
                >
                  Air
                </span>
                <span
                  className={` ${styles['Text07']} ${projectStyles['body']} `}
                >
                  Fire
                </span>
                <span
                  className={` ${styles['Text08']} ${projectStyles['body']} `}
                >
                  Water
                </span>
              </div>
              <div className={styles['container07']}>
                <span
                  className={` ${styles['text09']} ${projectStyles['body']} `}
                >
                  {props.Strength1}
                </span>
                <span
                  className={` ${styles['text10']} ${projectStyles['body']} `}
                >
                  {props.Intelligent1}
                </span>
                <span
                  className={` ${styles['Text11']} ${projectStyles['body']} `}
                >
                  {props.Agility1}
                </span>
                <span
                  className={` ${styles['Text12']} ${projectStyles['body']} `}
                >
                  {props.Earth1}
                </span>
                <span
                  className={` ${styles['Text13']} ${projectStyles['body']} `}
                >
                  {props.Air1}
                </span>
                <span
                  className={` ${styles['Text14']} ${projectStyles['body']} `}
                >
                  {props.Fire1}
                </span>
                <span
                  className={` ${styles['Text15']} ${projectStyles['body']} `}
                >
                  {props.Water1}
                </span>
              </div>
            </div>
          </div>
          <div className={styles['container08']}>
            <span className={styles['text16']}>Abilities</span>
            <div className={styles['container09']}>
              <img
                alt={props.image_alt2}
                src={props.image_src3}
                width={100} height={160}
                className={styles['image1']}
              />
              <img
                alt={props.image_alt3}
                src={props.image_src4}
                width={100} height={160}
                className={styles['image2']}
              />
            </div>
            <div className={styles['container10']}>
              <img
                alt={props.image_alt5}
                src={props.image_src6}
                width={100} height={160}
                className={styles['image3']}
              />
              <img
                alt={props.image_alt4}
                src={props.image_src5}
                width={100} height={160}
                className={styles['image4']}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

NFTInfoCard.defaultProps = {
  image_src5: '/playground_assets/10-200h.png',
  image_alt: 'image',
  image_alt4: 'image',
  NFTname: 'Magic Disciple - Warrior',
  image_alt3: 'image',
  image_src3: '/playground_assets/8-200h.png',
  Water1: '0',
  Strength1: '1',
  Agility: 'Agility',
  NFTimage: '/playground_assets/char-warrior-600w.jpg',
  Fire1: '1',
  image_src4: '/playground_assets/9-200h.png',
  rootClassName: '',
  image_alt2: 'image',
  image_src6: '/playground_assets/7-200h.png',
  image_alt5: 'image',
  Intelligent1: '2',
  Agility1: '1',
  Air1: '0',
  Earth1: '1',
}

NFTInfoCard.propTypes = {
  image_src5: PropTypes.string,
  image_alt: PropTypes.string,
  image_alt4: PropTypes.string,
  NFTname: PropTypes.string,
  image_alt3: PropTypes.string,
  image_src3: PropTypes.string,
  Water1: PropTypes.string,
  Strength1: PropTypes.string,
  Agility: PropTypes.string,
  NFTimage: PropTypes.string,
  Fire1: PropTypes.string,
  image_src4: PropTypes.string,
  rootClassName: PropTypes.string,
  image_alt2: PropTypes.string,
  image_src6: PropTypes.string,
  image_alt5: PropTypes.string,
  Intelligent1: PropTypes.string,
  Agility1: PropTypes.string,
  Air1: PropTypes.string,
  Earth1: PropTypes.string,
}

export default NFTInfoCard
