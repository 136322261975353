import React, {useState, useRef} from 'react'
import PropTypes from 'prop-types'

// import { Helmet } from 'react-helmet'

import BurgerMenu from '../components/burger-menu'
import Header from '../components/header'
import NFTInfoCard from '../components/n-f-t-info-card'
import NFTCraft from '../components/n-f-t-craft'
import Divider from '../components/divider'
import FeatureCard from '../components/feature-card'
import OutlineBlueButton from '../components/outline-blue-button'
import buttonStyles from '../components/outline-blue-button.module.css'

import RoadmapStepRight from '../components/roadmap-step-right'
import NameCard from '../components/name-card'
import Footer from '../components/footer'
import projectStyles from '../style.module.css'
import styles from './home.module.css'
import Phase1 from '../components/phase1'
import Phase2 from '../components/phase2'
import SoundControl from '../components/SoundControl'


import '../slick.css'
import '../slick-theme.css'
import Slider from 'react-slick'

import Zoom from 'react-reveal/Zoom'
// import RubberBand from 'react-reveal/RubberBand'
import Slide from 'react-reveal/Slide'
// import Flash from 'react-reveal/Flash'
// import Flip from 'react-reveal/Flip'

import ReactReadMoreReadLess from 'react-read-more-read-less'

//3D
import {Canvas, useFrame} from '@react-three/fiber/'
import {Suspense} from 'react'
// import { useLoader } from '@react-three/fiber'
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
// import { Environment, OrbitControls } from "@react-three/drei"
import {useGLTF, PerspectiveCamera, useAnimations} from '@react-three/drei'
import {Html, useProgress} from '@react-three/drei'

import ForegroundTrees from './ForegroundTrees.js'
import Leaf from './Leaf.js'
import LazyImage from '../components/LazyImage';
import {
  Modal,Typography,Button
} from 'antd';

function Loader() {
  const {active, progress, errors, item, loaded, total} = useProgress()
  // console.log({progress})

  if (progress != 100) {
    document.body.classList.add('stop-scrolling');
  }

  if (progress == 100) {
    setTimeout(function () {
      document.body.classList.remove('stop-scrolling');
    }, 1000);

  }


  return (
    <Html>
    </Html>
  )

}

//Slick carousel
const settings = {
  // className: "center",
  arrows: false,
  centerMode: true,
  infinite: true,
  // dots: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  focusOnSelect: true,
  centerPadding: '20%',
  speed: 1000,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,

      settings: {
        slidesToShow: 1,
        centerPadding: '15%'
      }
    },
    {
      breakpoint: 600,

      settings: {
        slidesToShow: 1,
        centerPadding: '20px'
      }
    },
    {
      breakpoint: 480,

      settings: {
        slidesToShow: 1,
        centerPadding: 0
      }
    }
  ]
};

const {Title} = Typography;
const Home = (props) => {
  const [appWelcome, setAppWelcome] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  // const [loading, setLoading] = useState(false);
  const sliderRef = useRef(null);

  // const { nodes, materials, animations } = useGLTF('/playground_assets/foregroundTrees.glb');
  

  const nftList = [
    {image: '/playground_assets/char-warrior.jpg', name: 'Magic Disciple - Paragon'},
    {image: '/playground_assets/char-alchemist.jpg', name: 'Magic Disciple - Alchemist'},
    {image: '/playground_assets/char-rogue.jpg', name: 'Magic Disciple - Rogue'},
    {image: '/playground_assets/char-mage.jpg', name: 'Magic Disciple - Invoker'},
  ];

  const bannerRef = useRef(null);
  const teamRef = useRef(null);
  const handleScroll = () => {
    teamRef.current?.scrollIntoView({behavior: 'smooth'});
  }
  const scrollTop = () => {
    bannerRef.current.scrollIntoView({behavior: 'smooth'});
  }


  return (

    <div className={styles['container']}>

      <Header handleScroll={handleScroll}/>
      <div ref={bannerRef} className={styles['Banner']}>
        <div className={styles['imageContainer']}>
          <LazyImage
            autoLoad={true}
            alt="Banner Background"
            src="/playground_assets/banner-background-2000w.jpg"
            height={1338} width={2560}
            className={styles['bannerImage']}
          />
          <img
            alt="Rune Circle"
            src="/playground_assets/rune-circle.png"
            className={styles['runeCircle']}
          />
          <img
            alt="Tower Light"
            src="/playground_assets/tower-light.png"
            className={styles['towerLight']}
          />


          <div className="canvasContainer">
            <Canvas>
              <Suspense fallback={<Loader/>}>
                <ForegroundTrees/>
              </Suspense>

            </Canvas>
          </div>

          <div className="canvasContainer">
            <Canvas>
              <Suspense fallback={null}>
                <Leaf/>
              </Suspense>

            </Canvas>
          </div>

        </div>
        <div className={styles['container01']}>
          <p className={styles['text']}>

            <a className={styles['text02']}>Welcome to<br/></a>
            <a className={styles['text04']}>The World of Magic<br/></a>

            <a className={styles['text05']}><br/>Start mining SHARDs for free <span style={{color: '#7de7ff'}}>(limited quantity)</span>
            </a>
            <OutlineBlueButton
              button="Register Now"
              rootClassName="rootClassName"
            />
          </p>
        </div>
      </div>
      {/* <div className={styles['fixed']}> */}
      {appWelcome&&<SoundControl sound={'/sound/OMS_MainTheme.mp3'}/>}
        {/* </div> */}

      <div className={styles['BodyContainer']}>

        {/* starry background */}
        <div className={styles['starryBackgroundContainer']}>
          <LazyImage
            alt="BodyTop"
            src="/playground_assets/BodyTop.svg"
            className={styles['BodyTop']}
          />
          <div
            className={styles['BodyBody']}
          />
          <div
            className={styles['BodyBottom']}
          />
        </div>

        <div className={styles['NFTDemo']}>
          <h1 className={` ${styles['titleText']} ${projectStyles['headingOne']} `}>
            Assemble your Forces

          </h1>
          <div className={` ${styles['bodyTextContainer']} ${projectStyles['body']} `}>
            <ReactReadMoreReadLess
              charLimit={200}
              readMoreText={'Read more ▼'}
              readLessText={'Read less ▲'}
              readMoreClassName={styles['read-more-less']}
              readLessClassName={styles['read-more-less']}
            >
              At One Magic School, players need to have at least one Magic Disciple (Free) to start exploring,
              battling monsters and gathering resources. The resources include the in-game cryptocurrency MAES and many
              types of material. These resources can then be used to forge new NFTs including skills & abilities,
              equipment, pets, and especially, new Magic Disciples. We are also planning to make the game free to play
              while keeping the benefits for NFT holders. More details will be released soon.

            </ReactReadMoreReadLess>
          </div>

          <div className={styles['container02']}>
            <img
              alt="image"
              src="/playground_assets/arrowleft-1500w.png"
              className={styles['arrow']}
              onClick={() => sliderRef.current.slickPrev()}
            />
            {nftList.map((nft, index) => {
              // console.log(slideIndex,index,slideIndex==index?"/playground_assets/radio-1500w.png":"/playground_assets/radioactive-1500w.png")
              return <img
                key={'NFT' + index}
                alt="image"
                src="/playground_assets/radio-1500w.png"
                // src={slideIndex !== index ? "/playground_assets/radio-1500w.png" : "/playground_assets/radioactive-1500w.png"}
                className={slideIndex !== index ? styles['dot'] : styles['dotActive']}
                onClick={() => sliderRef.current.slickGoTo(index)}
              />
            })}

            <img
              alt="image"
              src="/playground_assets/arrowright-1500w.png"
              className={styles['arrow']}
              onClick={() => sliderRef.current.slickNext()}
            />
          </div>
          {/* <div className={styles['container03']}> */}
          <Slide left>
            <div className={styles['container03']}>

              <Slider ref={sliderRef} style={{width: '100%', height: 'auto'}}  {...settings}
                      beforeChange={(oldIndex, newIndex) => setSlideIndex(newIndex)}>
                {nftList.map((nft, index) => {
                  return (

                    <div key={index}>
                      <div className={index === slideIndex ? styles['SlideActive'] : styles['SlideNormal']}>
                        <NFTInfoCard
                          NFTimage={nft.image}
                          NFTname={nft.name}

                        ></NFTInfoCard>
                      </div>
                    </div>

                  )
                })}
              </Slider>

            </div>
          </Slide>
        </div>
        <Divider rootClassName="rootClassName"></Divider>

        <Zoom>
          <div className={styles['section']}>
            <h1 className={styles['titleText']}>Explore the World</h1>
            <div className={` ${styles['bodyTextContainer']} ${projectStyles['body']} `}>
              <ReactReadMoreReadLess
                charLimit={200}
                readMoreText={'Read more ▼'}
                readLessText={'Read less ▲'}
                readMoreClassName={styles['read-more-less']}
                readLessClassName={styles['read-more-less']}
              >
                In this ONE world, players can explore the world full of mysteries, fight monsters, gather resources,
                and then, plan a strategic combination before crafting or buying new NFTs.

              </ReactReadMoreReadLess>
            </div>
            <div className={styles['container04']}>
              <div className={styles['imageBorder']}>
                <LazyImage
                  alt="image"
                  src="/playground_assets/world_map.jpg"
                  width={1400} height={963}
                  className={styles['image08']}
                />
              </div>
            </div>
          </div>
        </Zoom>
        <Divider rootClassName="rootClassName"></Divider>
        <Zoom>
          <div className={styles['section']}>
            <h1 className={styles['titleText']}>Face the Lurking Evil</h1>
            <div className={` ${styles['bodyTextContainer']} ${projectStyles['body']} `}>
              <ReactReadMoreReadLess
                charLimit={200}
                readMoreText={'Read more ▼'}
                readLessText={'Read less ▲'}
                readMoreClassName={styles['read-more-less']}
                readLessClassName={styles['read-more-less']}
              >
                As you can see, the gameplay is heavily inspired by the very successful turn-based strategy game
                "Darkest Dungeon". However, we do put in One Magic School a lot of our own ideas. We will leave it to
                you to do the exploration. One thing that is worth mentioning is that we have a powerful factor that
                neither Darkest Dungeon nor any other great games in the past have, the satisfaction of crafting and
                owning your valuable game assets as NFTs.

              </ReactReadMoreReadLess>
            </div>
            <div className={styles['container06']}>
              <div className={styles['imageBorder']}>
                <LazyImage
                  src="/playground_assets/battle-resize-1500w.jpg"
                  width={1500} height={844}
                  alt="image"
                  className={styles['image09']}
                />
              </div>
            </div>
          </div>
        </Zoom>
        <Divider rootClassName="rootClassName"></Divider>
        <Zoom>
          <div className={styles['section']}>
            <h1 className={styles['titleText']}>
              Craft your Own NFTs with Unique Strategies
            </h1>

            <div className={` ${styles['bodyTextContainer']} ${projectStyles['body']} `}>

              One Magic School offers a variety of NFT types that can
              interact with each other to form different strategies.
              NFT types include the following:

              {/* <div className={styles["grid-container"]}>
                <div class="item1">Magic Disciples</div>
                <div class="item2">Main character NFT with different traits and spec. Can be 
                fused with each other to unlock Source-Infused Magic Disciple, 
                a new powerful type of MD. This in turn unlocks other great abilities 
                called Sourcing.</div>

                <div class="item3">3</div>  
                <div class="item4">4</div>

                <div class="item5">5</div>
                <div class="item6">6</div>

                <div class="item7">7</div>
                <div class="item8">8</div> 
              </div> */}

            </div>

            <div className={styles['NFTCraftContainer']}>

              <NFTCraft></NFTCraft>


              <NFTCraft title="Equipment"
                        text="Weapons or equipment NFts, can be carried by Magic Disciples to battle. Unlocking specific types of skills. Can benefit from MD traits and spec."
                        image_src="/playground_assets/weapon-broad-sword.jpg"
                        image_src1="/playground_assets/weapon-fire-liquid.jpg"
                        image_src2="/playground_assets/weapon-hand-of-frost.jpg"
                        image_src3="/playground_assets/weapon-pointy-dagger.jpg"
              ></NFTCraft>

              <NFTCraft title="Skills"
                        text="Can be carried by Magic Disciples to battle. Use in combination with equipment and main character. Can benefit from MD traits and spec."
                        image_src="/playground_assets/skill-fireball.jpg"
                        image_src1="/playground_assets/skill-mortal-strike.jpg"
                        image_src2="/playground_assets/skill-poison-spit.jpg"
                        image_src3="/playground_assets/skill-entangling-web.jpg"
              ></NFTCraft>

              <NFTCraft title="Pets"
                        text="MD with certain traits can acquire the “Tame” ability, allowing them to capture and raise animal pups to become pets. These pets can be brought to battle or used for defi farming at home."
                        image_src="/playground_assets/pet-duckling.jpg"
                        image_src1="/playground_assets/pet-wolf.jpg"
                        image_src2="/playground_assets/pet-owl.jpg"
                        image_src3="/playground_assets/pet-dragon.jpg"
              ></NFTCraft>

            </div>
          </div>
        </Zoom>
        <Divider rootClassName="rootClassName"></Divider>
        <Zoom>
          <div className={styles['section']}>

            <h1 className={styles['titleText']}>Profit</h1>
            <div className={` ${styles['bodyTextContainer']} ${projectStyles['body']} `}>
              <ReactReadMoreReadLess
                charLimit={200}
                readMoreText={'Read more ▼'}
                readLessText={'Read less ▲'}
                readMoreClassName={styles['read-more-less']}
                readLessClassName={styles['read-more-less']}
              >
                We have developed a sophisticated economic system to allow players to be rewarded with real money while
                keeping the game's long-term sustainability. Players can contribute to the game just by playing it hard.
                On the other hand, players with special interests in creativity will have a chance to contribute and
                earn more via our ecosystem fund. You can find more details about our ecosystem in our whitepaper. For
                now, the general profits everyone cares about include the following.

              </ReactReadMoreReadLess>
            </div>
            <div className={styles['ProfitRow']}>
              <div className={styles['ProfitCol']}>
                <div className={styles['ProfitItem']}>
                  <img
                    alt="image"
                    src="/playground_assets/shard.png"
                    className={styles['profitImage']}
                  />
                </div>
                <span className={styles['profitText']}>Earn Shard Rewards</span>
              </div>
              <div className={styles['ProfitCol']}>
                <div className={styles['ProfitItem']}>
                  <img
                    alt="image"
                    src="/playground_assets/treasure.png"
                    className={styles['profitImage']}
                  />
                </div>
                <span className={styles['profitText']}>NFT Marketplace</span>
              </div>
              <div className={styles['ProfitCol']}>
                <div className={styles['ProfitItem']}>
                  <img
                    alt="image"
                    src="/playground_assets/shard_farm.png"
                    className={styles['profitImage']}
                  />
                </div>
                <span className={styles['profitText']}>Yield Farming</span>
              </div>
            </div>
          </div>
        </Zoom>

        <Divider rootClassName="rootClassName"></Divider>

        <Zoom>
          <section className={styles['section']}>
            <h1 className={styles['titleText']}>Key Differences</h1>

            <div className={styles['ProfitRow']}>
              <FeatureCard
                text="A turn base strategy game with interesting storyline, designed to be a really enjoyable game."
                title="Great Gameplay"
                image_src="/playground_assets/cube1.svg"
              ></FeatureCard>
              <FeatureCard
                text="Many types of NFTs for you to explore and combine. The limit is your imagination."
                title="Rich NFT Variations"
                image_src="/playground_assets/person1.svg"
              ></FeatureCard>
              <FeatureCard
                text="With the power of the Polygon network, transactions can be done in lightning speed with minimal fees."
                title="Low Gas, High Speed"
                image_src="/playground_assets/rocket1.svg"
              ></FeatureCard>
              <FeatureCard
                text='The "play to earn" mechanism is carefully calculated for the next 5 years sustainability.'
                title="Long-term sustainability"
                image_src="/playground_assets/credit%20card1.svg"
              ></FeatureCard>
            </div>
          </section>
        </Zoom>

        <Divider rootClassName="rootClassName"></Divider>

        <Zoom>
          <section className={styles['Roadmap']}>
            <div className={styles['container10']}>
              <h1 className={styles['Heading']}>
                <span className={styles['text10']}>Roadmap</span>
              </h1>
            </div>
            <span
              className={` ${styles['text11']} ${projectStyles['headingThree']} `}
            >
              <span>
                We are putting an aggressive roadmap because the technology nowadays
                has greatly developed; it allows developers to be much quicker in
                their product delivery.
              </span>
              <br></br>
              <span></span>
            </span>
            <img
              alt="image"
              src="/playground_assets/radioactive-200h.png"
              className={styles['DotStart']}
            />
            <Phase1></Phase1>
            <Phase2

            ></Phase2>
            <div className={styles['Phase1']}>
              <div className={styles['container11']}>
                <div className={styles['container12']}>
                  <div
                    className={` ${styles['container13']} ${projectStyles['RoadmapStep']} `}
                  >
                    <div className={styles['container14']}></div>
                    <span
                      className={` ${styles['text14']} ${projectStyles['headingTwo']} `}
                    >
                      Phase 3: Public Sale
                    </span>
                    <div className={styles['container15']}></div>
                  </div>
                </div>
                <ul
                  className={` ${projectStyles['body']} ${projectStyles['list']} `}
                >
                  <li className={projectStyles['list-item']}>
                    <span>SHARD token public sale.</span>
                  </li>
                  <li className={projectStyles['list-item']}>
                    <span>SHARD reward to Genesis holders begins.</span>
                  </li>
                </ul>
              </div>
              <div className={styles['VerticalLine']}></div>
            </div>
            <div className={styles['Phase2']}>
              <div className={styles['container16']}>
                <div
                  className={` ${styles['container17']} ${projectStyles['RoadmapStep']} `}
                >
                  <div className={styles['container18']}></div>
                  <span
                    className={` ${styles['text17']} ${projectStyles['headingTwo']} `}
                  >
                    Phase 4: Alpha
                  </span>
                </div>
                <ul
                  className={` ${projectStyles['list']} ${projectStyles['body']} `}
                >
                  <li className={projectStyles['list-item']}>
                    <span>Game Alpha release.</span>
                  </li>
                  <li className={projectStyles['list-item']}>
                    <span>
                      {' '}
                      Introduce the Magic Disciple NFTs. (basic, non-NFT equipment
                      and skills)
                    </span>
                  </li>
                </ul>
              </div>
              <div className={styles['VerticalLine1']}></div>
            </div>
            <Phase1
              text="Game Beta release."
              Phase="Phase 5: Beta"
              text1="Introduce the Pet NFTs."
              text2="Introduce the forging mechanism (Equipment and skills NFTs)."
            ></Phase1>
            <div className={styles['Phase6']}>
              <div className={styles['container19']}>
                <div className={styles['container20']}></div>
              </div>
              <div className={styles['VerticalLine2']}></div>
              <div className={styles['container21']}>
                <RoadmapStepRight
                  text="Phase 6: Release &amp; Expand"
                  rootClassName="rootClassName3"
                ></RoadmapStepRight>
                <ul
                  className={` ${projectStyles['list']} ${projectStyles['body']} `}
                >
                  <li className={projectStyles['list-item']}>
                    <span>
                      <span>
                        Full release.
                      </span>
                      <br></br>
                      <span></span>
                    </span>
                  </li>
                  <li className={projectStyles['list-item']}>
                    <span>Expansion development (Land, PvP, factions, professions,
                      etc.).</span>
                  </li>
                </ul>
              </div>
            </div>
            <img
              alt="image"
              src="/playground_assets/radioactive-200h.png"
              className={styles['DotEnd']}
            />
          </section>
        </Zoom>

        <Divider rootClassName="rootClassName1"></Divider>

        <div ref={teamRef} className={styles['Team']}>
        <Zoom>
            <h1 className={styles['titleText']}>Our Team</h1>
            <div className={` ${styles['bodyTextContainer']} ${projectStyles['body']} `}>
              <ReactReadMoreReadLess
                charLimit={200}
                readMoreText={"Read more ▼"}
                readLessText={"Read less ▲"}
                readMoreClassName={styles["read-more-less"]}
                readLessClassName={styles["read-more-less"]}
              >
                Our team is a combination of engineers, artists, and business experts. We are not only serious workers but also passionate and hardcore gamers. We know what makes a game feels good and we have the skills to make it happen.
              </ReactReadMoreReadLess>
            </div>
            <div className={styles['container22']}>
              <NameCard rootClassName="rootClassName"
                image_src='/playground_assets/AnPhan.jpg'
                Title="CCO, Co-Founder"
                linkedIn='https://www.linkedin.com/in/honganphan/'
                displayLinkedin='link'
                // twitterIcon='https://twitter.com/PantonPhan'
                // displayTwitter='link'
                // facebookIcon='https://www.facebook.com/phangiang.hongan/'
                // displayFB='link'
              />

              <NameCard
                Name="Nhan Tran, eMBA"
                Title="CEO, Co-Founder"
                rootClassName="rootClassName1"
                image_src='/playground_assets/NhanTran.jpg'
                linkedIn='https://www.linkedin.com/in/tri-nhan-tran/'
                displayLinkedin='link'
                // facebookIcon='https://www.facebook.com/nhadatmoi.helper'
                // displayFB='link'
              />

              {/* <NameCard
                Name="Johny Nguyen, MBA"
                Title="Co-Founder"
                rootClassName="rootClassName1"
                image_src='/playground_assets/JohnyNguyen.jpg'
                twitterIcon='https://twitter.com/johnydnguyen'
                displayTwitter='link'
              /> */}

              <NameCard
                Name="Tom Huynh, MSc"
                Title="CTO, Co-Founder"
                rootClassName="rootClassName1"
                image_src='/playground_assets/TomHuynh.jpg'
                linkedIn='https://www.linkedin.com/in/tomhuynh/'
                displayLinkedin='link'
              // facebookIcon='https://www.facebook.com/rocky.anderson.984'
              // displayFB='link'
              >
              </NameCard>

            </div>
            <div className={styles['container22']}>
              <NameCard
                Name="Jacky Nguyen"
                Title="Founding Engineer"
                rootClassName="rootClassName1"
                image_src='/playground_assets/Jacky.webp'
                linkedIn='https://www.linkedin.com/in/jacky-nguyen-3454b612a/'
                displayLinkedin='link'
              // facebookIcon='https://www.facebook.com/rocky.anderson.984'
              // displayFB='link'
              >
              </NameCard>
              <NameCard
                Name="Long Huynh"
                Title="Founding Engineer"
                rootClassName="rootClassName1"
                image_src='/playground_assets/LongHuynh.jpg'
                linkedIn='https://www.linkedin.com/in/longhb'
                displayLinkedin='link'
              // facebookIcon='https://www.facebook.com/rocky.anderson.984'
              // displayFB='link'
              >
              </NameCard>
              <NameCard
                Name="Bao Pham"
                Title="Developer"
                rootClassName="rootClassName1"
                image_src='/playground_assets/BaoPham.jpg'
                // artStation='https://www.artstation.com/daubiec'
                // displayArtStation='link'
              // facebookIcon='https://www.facebook.com/rocky.anderson.984'
              // displayFB='link'
              />
              <NameCard
                Name="Uyen Tran"
                Title="2D Artist"
                rootClassName="rootClassName1" 
                image_src='/playground_assets/UyenTran.jpg'
                artStation='https://www.artstation.com/daubiec'
                displayArtStation='link'
              // facebookIcon='https://www.facebook.com/rocky.anderson.984'
              // displayFB='link'
              />
              {/* <NameCard
                Name="Vy Kobe"
                Title="Community Manager"
                rootClassName="rootClassName1"
                image_src='/playground_assets/VyKobe.jpg'
                // artStation='https://www.artstation.com/daubiec'
                // displayArtStation='link'
              // facebookIcon='https://www.facebook.com/rocky.anderson.984'
              // displayFB='link'
              /> */}
              
            </div>
          </Zoom>
          <img
            alt="image"
            src="/playground_assets/bottomclouds-1500w.png"
            className={styles['image14']}
          />
        </div>
      </div>

      
      <Modal className="modalLoading" open={!appWelcome} footer={null} closable={false}>
        <div  className={'welcome-wrapper'} ><div className={'welcome-inner-container'}>
        <p className={styles['text']}>

            <a className={styles['text02']}>Welcome to<br/></a>
            <a className={styles['text04']}>One Magic School<br/></a>

            <a className={styles['text05']}><br/>A <span className={styles['read-more-less']}>Fun - Free - Earn</span> blockchain game. <br/> Made by Gamers for Gamers.</a>
            <br></br>
            <br></br>
            <button className={buttonStyles['button']} onClick={()=>setAppWelcome(true)}>Continue</button>
            </p>
          
          
        </div></div>
      </Modal>
      <Footer rootClassName="rootClassName"></Footer>


    </div>


  )
}
// useGLTF.preload('/foregroundTrees.glb')
export default Home

