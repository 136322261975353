/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useEffect, useRef} from 'react'
import {useGLTF, PerspectiveCamera, useAnimations} from '@react-three/drei'

export default function Model({...props}) {
  const group = useRef()
  const {nodes, materials, animations} = useGLTF('/playground_assets/foregroundTrees.glb')
  const {actions} = useAnimations(animations, group)
  useEffect(() => {
    actions.ArmatureAction.play()
    actions.ArmatureRightAction.play()
  })
  useEffect(() => {
    if (group.current && document.querySelector('.loaderwrapper')) {
      document.querySelector('.loaderwrapper').remove();
    }
  }, [group.current])
  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <group position={[0, 8.18, 0.02]} scale={[1, 1, 1.01]}>
          <PerspectiveCamera makeDefault={true} far={100} near={0.1} fov={13.64} rotation={[-Math.PI / 2, 0, 0]}/>
        </group>
        <group name="Armature" position={[-1.76, 0, 0.91]} scale={[1, 1, 1.01]}>
          <primitive object={nodes.Bone}/>
          <primitive object={nodes.pine1}/>
          <primitive object={nodes.pine2}/>
          <skinnedMesh geometry={nodes.Plane.geometry} material={materials['Material.001']}
                       skeleton={nodes.Plane.skeleton}/>
        </group>
        <group name="ArmatureRight" position={[1.82, 0, 0.86]} scale={[1, 1, 1.01]}>
          <primitive object={nodes.pine1_1}/>
          <primitive object={nodes.pine2_1}/>
          <primitive object={nodes.Bone_1}/>
          <primitive object={nodes.neutral_bone}/>
          <skinnedMesh geometry={nodes.Plane001.geometry} material={materials['Material.002']}
                       skeleton={nodes.Plane001.skeleton}/>
        </group>
      </group>
    </group>
  )
}

