import React from 'react'

import PropTypes from 'prop-types'

import projectStyles from '../style.module.css'
import styles from './n-f-t-craft.module.css'
import ReactReadMoreReadLess from "react-read-more-read-less"
import LazyImage from './LazyImage';



const NFTCraft = (props) => {
  return (
    <div className={styles['container']}>
      <div className={styles['textContainer']}>
        <div className={` ${styles['title']} ${projectStyles['headingThree']} `}>
          {props.title}
        </div>
        <div className={` ${styles['bodyTextContainer']} ${projectStyles['body']} `}>
          <ReactReadMoreReadLess
            charLimit={50}
            readMoreText={"Read more ▼"}
            readLessText={"Read less ▲"}
            readMoreClassName={styles["read-more-less"]}
            readLessClassName={styles["read-more-less"]}
          >
            {props.text}
          </ReactReadMoreReadLess>
        </div>
      </div>
      <div className={styles['imageContainer']}>
        
        <img
          alt={props.image_alt}
          src={props.image_src}
          className={styles['image']}
        />
        <img
          alt={props.image_alt1}
          src={props.image_src1}
          className={styles['image']}
        />
        <img
          alt={props.image_alt2}
          src={props.image_src2}
          className={styles['image']}
        />
        <img
          alt={props.image_alt3}
          src={props.image_src3}
          className={styles['image']}
        />
        
      </div>
      
    </div>
  )
}

NFTCraft.defaultProps = {
  title: 'Magic Disciples',
  text: 'Main characters with different traits and spec. Can be fused with each other to unlock Source-Infused Magic Disciple, a new powerful type of MD. This in turn unlocks other great abilities called Sourcing.',
  image_alt: 'image',
  image_alt1: 'image',
  image_alt2: 'image',
  image_alt3: 'image',
  image_src: '/playground_assets/char-warrior.jpg',
  image_src1: '/playground_assets/char-mage.jpg',
  image_src2: '/playground_assets/char-rogue.jpg',
  image_src3: '/playground_assets/char-alchemist.jpg',
}

NFTCraft.propTypes = {
  text: PropTypes.string,
  image_src: PropTypes.string,
  image_src1: PropTypes.string,
  image_src2: PropTypes.string,
  image_src3: PropTypes.string,
  image_alt: PropTypes.string,
  image_alt1: PropTypes.string,
  image_alt2: PropTypes.string,
  image_alt3: PropTypes.string,
}

export default NFTCraft
