import React from 'react'

import PropTypes from 'prop-types'

import projectStyles from '../style.module.css'
import styles from './roadmap-step-right.module.css'

const RoadmapStepRight = (props) => {
  return (
    <div
      className={` ${styles['container']} ${projectStyles['RoadmapStep']} ${
        styles[props.rootClassName]
      } `}
    >
      <div className={styles['container1']}></div>
      <span className={` ${styles['text']} ${projectStyles['headingTwo']} `}>
        {props.text}
      </span>
    </div>
  )
}

RoadmapStepRight.defaultProps = {
  text: 'Phase 1: ABCD',
  rootClassName: '',
}

RoadmapStepRight.propTypes = {
  text: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default RoadmapStepRight
