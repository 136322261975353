/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF, PerspectiveCamera, useAnimations } from '@react-three/drei'
import { arrayOf } from 'prop-types'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/playground_assets/leaf.glb')
  const { ref, actions, mixer, names, clips } = useAnimations(animations, group)
  useEffect(() => {
    for(let i=0;i<names.length;i++)
      actions[names[i]].play()

    // actions[names[0]].play()
    // actions[names[1]].play()
    // actions[names[2]].play()
    // actions[names[3]].play()
    // actions[names[4]].play()
    // actions[names[5]].play()
    // actions[names[6]].play()
    
  })

  return (
    <group ref={group} {...props} dispose={null}>
    <group>
      <group position={[-0.1, 0, 35.47]} rotation={[1.53, 0, 0]}>
        <PerspectiveCamera makeDefault={true} far={1000} near={0.1} fov={41.41} rotation={[-Math.PI / 2, 0, 0]} />
      </group>
        <mesh name="particle000" geometry={nodes.particle000.geometry} material={materials.leaf} position={[0.65, -5.68, 23.14]} rotation={[1.66, -0.17, 0.77]} scale={0.69} />
        <mesh name="particle001" geometry={nodes.particle001.geometry} material={materials.leaf} position={[2.11, -23.71, 13.45]} rotation={[-3.09, -0.54, 3.05]} scale={0.51} />
        <mesh name="particle002" geometry={nodes.particle002.geometry} material={materials.leaf} position={[10.17, -13.41, 5.01]} rotation={[1.16, 0.44, -2.07]} scale={0.75} />
        <mesh name="particle003" geometry={nodes.particle003.geometry} material={materials.leaf} position={[12.04, -8.75, 18.07]} rotation={[-0.86, -0.43, -2.93]} scale={0.57} />
        <mesh name="particle004" geometry={nodes.particle004.geometry} material={materials.leaf} position={[-2.37, -4.51, 12.79]} rotation={[1.13, -0.17, 0.29]} scale={0.73} />
        <mesh name="particle005" geometry={nodes.particle005.geometry} material={materials.leaf} position={[-5.75, -3.82, 12.01]} rotation={[-0.51, 0.08, -2.13]} scale={0.77} />
        <mesh name="particle006" geometry={nodes.particle006.geometry} material={materials.leaf} position={[4.53, -3.58, 15.78]} rotation={[0.03, 0.14, 1.1]} scale={0.59} />
        <mesh name="particle007" geometry={nodes.particle007.geometry} material={materials.leaf} position={[0.27, -13.1, 7.23]} rotation={[-2.97, -0.07, -2.29]} scale={0.59} />
        <mesh name="particle008" geometry={nodes.particle008.geometry} material={materials.leaf} position={[10.43, 10.14, 0.43]} rotation={[-0.15, -0.02, -1.31]} scale={0.6} />
        <mesh name="particle009" geometry={nodes.particle009.geometry} material={materials.leaf} position={[15.11, -2.91, 14.61]} rotation={[0.45, -1.33, -1.49]} scale={0.71} />
        <mesh name="particle010" geometry={nodes.particle010.geometry} material={materials.leaf} position={[-8.61, -9.56, 15.16]} rotation={[-2.04, 0.16, 2.77]} scale={0.77} />
        <mesh name="particle011" geometry={nodes.particle011.geometry} material={materials.leaf} position={[-0.02, -1.42, 13.71]} rotation={[0.54, -1.2, 1.83]} scale={0.52} />
        <mesh name="particle012" geometry={nodes.particle012.geometry} material={materials.leaf} position={[5.54, -10.41, 12.77]} rotation={[-2.13, -0.34, 0.67]} scale={0.66} />
        <mesh name="particle013" geometry={nodes.particle013.geometry} material={materials.leaf} position={[-13.99, 6.29, 8.91]} rotation={[0.46, -1.26, -2.47]} scale={0.69} />
        <mesh name="particle014" geometry={nodes.particle014.geometry} material={materials.leaf} position={[-6.81, 1.54, 6.79]} rotation={[0.98, -0.03, 2.08]} scale={0.7} />
        <mesh name="particle015" geometry={nodes.particle015.geometry} material={materials.leaf} position={[14.87, 8.01, 5]} rotation={[-1.63, -0.74, 0.65]} scale={0.49} />
        <mesh name="particle016" geometry={nodes.particle016.geometry} material={materials.leaf} position={[23.89, -4.15, -4.56]} rotation={[2.85, 0.87, 1.37]} scale={0.68} />
        <mesh name="particle017" geometry={nodes.particle017.geometry} material={materials.leaf} position={[3.92, -1.95, 17.73]} rotation={[2.37, -1.1, -1.53]} scale={0.74} />
        <mesh name="particle018" geometry={nodes.particle018.geometry} material={materials.leaf} position={[12.45, 6.21, 8.36]} rotation={[3.12, -0.07, 1.69]} scale={0.55} />
        <mesh name="particle019" geometry={nodes.particle019.geometry} material={materials.leaf} position={[0.39, 7.25, -0.63]} rotation={[1.91, 0.21, -1.49]} scale={0.61} />
        <mesh name="particle020" geometry={nodes.particle020.geometry} material={materials.leaf} position={[25.16, 7.21, 6.1]} rotation={[-2.65, 0.69, 0.24]} scale={0.65} />
        <mesh name="particle021" geometry={nodes.particle021.geometry} material={materials.leaf} position={[-22.26, 5, 7.65]} rotation={[-1.56, 0, 0.85]} scale={0.68} />
        <mesh name="particle022" geometry={nodes.particle022.geometry} material={materials.leaf} position={[2.41, 4.26, -0.52]} rotation={[0.95, -1.15, -2.63]} scale={0.61} />
        <mesh name="particle023" geometry={nodes.particle023.geometry} material={materials.leaf} position={[15.88, 5.19, 6.44]} rotation={[-1.13, -0.21, -0.54]} scale={0.78} />
        <mesh name="particle024" geometry={nodes.particle024.geometry} material={materials.leaf} position={[13.87, 6.55, 6.65]} rotation={[0.76, -0.24, -2.95]} scale={0.59} />
        <mesh name="particle025" geometry={nodes.particle025.geometry} material={materials.leaf} position={[-9.16, 12.53, 2.48]} rotation={[-1.1, 0.62, 1.4]} scale={0.69} />
        <mesh name="particle026" geometry={nodes.particle026.geometry} material={materials.leaf} position={[2.76, -22.92, 11.05]} rotation={[-2.95, -0.63, 3.05]} scale={0} />
        <mesh name="particle027" geometry={nodes.particle027.geometry} material={materials.leaf} position={[11.17, -13.37, 5.99]} rotation={[0.94, 0.78, -1.96]} scale={0} />
        <mesh name="particle028" geometry={nodes.particle028.geometry} material={materials.leaf} position={[11.02, -8.85, 17.85]} rotation={[-1.02, -0.22, 3.06]} scale={0} />
        <mesh name="particle029" geometry={nodes.particle029.geometry} material={materials.leaf} position={[-2.5, -4.38, 12.57]} rotation={[1.1, -0.14, 0.18]} scale={0} />
        </group>
  </group>
  )
}

useGLTF.preload('/foregroundTrees.glb')
